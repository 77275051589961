import service from '../services/global';

export default {
  namespace: 'global',

  state: {
    collapsed: false,
    notices: [],
    CurrentInfoData: [],
    CompanyInfoData: [],
  },

  effects: {
    // 异步请求个人信息
    *fetchCurrent({ payload }, { call, put }) {
      const result = yield call(service.fetchCurrent, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'CurrentInfoData',
        result,
      });
    },
    // 异步更新个人信息
    *patchCurrent({ payload }, { call, put }) {
      const result = yield call(service.patchCurrent, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'UpdateCurrentInfo',
        result,
      });
    },

    // 微信解绑
    *handleWxUnbind({ payload }, { call, put }) {
      const result = yield call(service.handleWxUnbind, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'UnbindMessage',
        result,
      });
    },

    // 异步请求企业信息
    *fetchCompany({ payload }, { call, put }) {
      const result = yield call(service.fetchCompany, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'CompanyInfoData',
        result,
      });
      return result;
    },
    // 异步更新企业信息
    *patchCompany({ payload }, { call, put }) {
      const result = yield call(service.patchCompany, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'UpdateCompanyInfo',
        result,
      });
    },

    //异步企业延期
    *extensionTime({ payload }, { call, put }) {
      const result = yield call(service.extensionTime, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'getExtensionTime',
        result,
      });
      return result;
    },

    // 异步获取企业应用列表
    *getEnterAppList({ payload }, { call, put }) {
      const result = yield call(service.getEnterAppList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveEnterAppData',
        result,
      });
    },
    *createEnterApp({ payload }, { call, put }) {
      const result = yield call(service.createEnterApp, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createEnterAppData',
        result,
      });
    },
    *deleteEnterApp({ payload }, { call, put }) {
      const result = yield call(service.deleteEnterApp, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteEnterAppData',
          result,
          payload,
        });
      }
    },
    *updateEnterApp({ payload }, { call, put }) {
      const result = yield call(service.updateEnterApp, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateEnterAppData',
        result,
      });
    },

    // 异步请求验证个人账户手机号重复
    *UserCheck({ payload }, { call, put }) {
      const result = yield call(service.UserCheck, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'UserCheckRes',
          result,
        });
      }
    },

    // 异步请求个人账户手机号验证码
    *getCaptcha({ payload }, { call, put }) {
      const result = yield call(service.getCaptcha, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'getCaptchaRes',
          result,
        });
      }
    },

    // 异步请求个人账户修改手机号
    *changeBindPhone({ payload }, { call, put }) {
      const result = yield call(service.changeBindPhone, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'changeBindPhoneRes',
          result,
        });
      }
    },

  },

  reducers: {
    // 获取、修改个人信息
    CurrentInfoData(state, { result: CurrentInfoData }) {
      return { ...state, CurrentInfoData };
    },
    UpdateCurrentInfo(state, { result }) {
      state.CurrentInfoData = result;
      return { ...state };
    },

    // 微信解绑
    UnbindMessage(state, { result: UnbindMsgData }) {
      return { ...state, UnbindMsgData };
    },

    // 获取、修改企业信息
    CompanyInfoData(state, { result: CompanyInfoData }) {
      return { ...state, CompanyInfoData };
    },
    UpdateCompanyInfo(state, { result }) {
      state.CompanyInfoData = result;
      return { ...state };
    },

    // 获取、新增修改删除企业应用
    saveEnterAppData(state, { result: EnterAppData }) {
      return {
        ...state,
        EnterAppData,
      };
    },
    createEnterAppData(state, { result: EnterAppData }) {
      state.EnterAppData = [EnterAppData].concat(state.EnterAppData);
      return {
        ...state,
      };
    },
    updateEnterAppData(state, { result }) {
      state.EnterAppData = state.EnterAppData.map(item => {
        return item.id === result.id ? result : item;
      });
      return { ...state };
    },
    deleteEnterAppData(state, { result: deleteEnterAppMes, payload }) {

      if (deleteEnterAppMes && deleteEnterAppMes.error) {
        // 检验报错
        return {
          ...state,
          deleteEnterAppMes,
        };
      }
      if (deleteEnterAppMes && deleteEnterAppMes.message === 'ok') {
        const newEnterAppData = state.EnterAppData.filter(item => item.id !== payload);
        return {
          ...state,
          EnterAppData: newEnterAppData,
          deleteEnterAppMes
        };
      }
    },

    UserCheckRes(state, { result: UserCheckResData }) {
      return {
        ...state,
        UserCheckResData,
      };
    },
    getCaptchaRes(state, { result: captchaResData }) {
      return {
        ...state,
        captchaResData,
      };
    },
    changeBindPhoneRes(state, { result: changeBindPhoneResData }) {
      return {
        ...state,
        changeBindPhoneResData,
      };
    },

    // 主页菜单缩进按钮控制
    changeLayoutCollapsed(state, { payload }) {
      return {
        ...state,
        collapsed: payload,
      };
    },
  },
};
