import service from '../services/data';

export default {
  namespace: 'data',
  // 模型
  state: {},

  // 异步方法，主要从定义异步请求
  effects: {
    // 异步请求版本信息
    *getVersionTxt({ payload }, { call, put }) {
      const result = yield call(service.getVersionTxt, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveVersionTxt',
        result,
      });
    },

    // 获取企业日产量统计列表
    *getStatisticList({ payload }, { call, put }) {
      const result = yield call(service.getStatisticList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results && result.results.map(items => ({
        ...items,
        total_count: items.data.reduce((total, current) => {
          return total + current.product_count;
        }, 0)
      }));
      const count = result.count;

      yield put({
        type: 'saveStatisticList',
        data,
        count,
      });
    },
    // 异步请求单机设备历史产量数据
    *getStatisticDeviceHistory({ payload }, { call, put }) {
      const result = yield call(service.getStatisticDeviceHistory, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveStatisticDeviceHistory',
        result,
      });
      return result;
    },
    // 异步请求单机设备历史产量数据
    *getStatisticProductLineHistory({ payload }, { call, put }) {
      const result = yield call(service.getStatisticProductLineHistory, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveStatisticProductLineHistory',
        result,
      });
      return result;
    },
    // 异步请求人员历史产量数据
    *getUsersProductHistory({ payload }, { call, put }) {
      const result = yield call(service.getUsersProductHistory, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveUsersProductHistory',
        result,
      });
      return result;
    },
    // 异步获取人员生产日志列表
    *getUserProductionLog({ payload }, { call, put }) {
      const result = yield call(service.getUserProductionLog, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveUserProductionLog',
        result,
      });
      return result;
    },
    // 异步获取人员某日生产日志列表
    *getUserDailyProductionLog({ payload }, { call, put }) {
      const result = yield call(service.getUserDailyProductionLog, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveUserDailyProductionLog',
        result,
      });
      return result;
    },

    //异步请求企业产量统计列表
    *getProductYields({ payload }, { call, put }) {
      const result = yield call(service.getProductYields, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveProductYields',
        result,
      });
      return result;
    },
    //异步请求日产量统计列表
    *getDailyProductYields({ payload }, { call, put }) {
      const result = yield call(service.getDailyProductYields, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDailyProductYields',
        result,
      });
      return result;
    },
    //异步请求人员产量统计列表
    *getUsersProductionWorkCount({ payload }, { call, put }) {
      const result = yield call(service.getUsersProductionWorkCount, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveUsersProductionWorkCount',
        result,
      });
      return result;
    },
    //异步获取人员某日产量统计列表
    *getUsersProductionWorkDetail({ payload }, { call, put }) {
      const result = yield call(service.getUsersProductionWorkDetail, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveUsersProductionWorkDetail',
        result,
      });
      return result;
    },
    //异步请求企业班组产量统计列表
    *getDailyProductYieldsInfoHistory({ payload }, { call, put }) {
      const result = yield call(service.getDailyProductYieldsInfoHistory, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDailyProductYieldsInfoHistory',
        result,
      });
      return result;
    },
    //异步请求产线状态信息
    *getProductLineStatus({ payload }, { call, put }) {
      const result = yield call(service.getProductLineStatus, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveProductStatusYields',
        result,
      });
      return result;
    },
    // 获取当前产线今日加工件数
    *getProductLineTodayFinish({ payload }, { call, put }) {
      const result = yield call(service.getProductLineTodayFinish, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'savegetProductLineTodayFinish',
        result,
      });
      return result;
    },
    //异步请求某日任务产量列表
    *getOneDayProductYields({ payload }, { call, put }) {
      const result = yield call(service.getOneDayProductYields, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveOneDayProductYields',
        result,
      });
      return result;
    },
    //异步请求某日任务产量列表
    *getOneDayOffProductYields({ payload }, { call, put }) {
      const result = yield call(service.getOneDayOffProductYields, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveOneDayOffProductYields',
        result,
      });
      return result;
    },
    //异步请求某日任务产量列表
    *getDailyProductTaskYields({ payload }, { call, put }) {
      const result = yield call(service.getDailyProductTaskYields, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDailyProductTaskYields',
        result,
      });
      return result;
    },
    //异步请求某日非加工任务产量列表
    *getDailyProductNonTaskYields({ payload }, { call, put }) {
      const result = yield call(service.getDailyProductNonTaskYields, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDailyProductNonTaskYields',
        result,
      });
      return result;
    },
    //异步获取所有任务编号列表
    *getAllTaskNumber({ payload }, { call, put }) {
      const result = yield call(service.getAllTaskNumber, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveAllTaskNumber',
        result,
      });
      return result;
    },
    //异步获取所有人员姓名
    *getAllUsersName({ payload }, { call, put }) {
      const result = yield call(service.getAllUsersName, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveAllUsersName',
        result,
      });
      return result;
    },

    // 异步请求人员角色分组列表
    *getGroupList({ payload }, { call, put }) {
      const result = yield call(service.getGroupList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveGroupsList',
        result,
      });
    },
    // 异步请求人员列表
    *getPersonList({ payload }, { call, put }) {
      const result = yield call(service.getPersonList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'savePersonList',
        result,
      });
      return result;
    },
    *getPersonNameList({ payload }, { call, put }) {
      const result = yield call(service.getPersonNameList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'savePersonNameList',
        result,
      });
    },
    // 异步新增人员列表
    *createPerson({ payload }, { call, put }) {
      const result = yield call(service.createPerson, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createPersonList',
        result,
      });
      return result;
    },
    // 异步删除人员列表
    *deletePerson({ payload }, { call, put }) {
      const result = yield call(service.deletePerson, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deletePersonList',
          result,
        });
      }
      return result;
    },
    // 异步更新人员列表
    *updatePerson({ payload }, { call, put }) {
      const result = yield call(service.updatePerson, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updatePersonList',
        result,
      });
    },
    //异步请求公司和部门列表
    *getCompanyTeam({ payload }, { call, put }) {
      const result = yield call(service.getCompanyTeam, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCompanyTeamList',
        result,
      });
      return result;
    },
    //异步请求企业列表
    *getCompaniesList({ payload }, { call, put }) {
      const result = yield call(service.getCompaniesList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCompaniesList',
        result,
      });
      return result;
    },
    //异步创建企业列表
    *createCompaniesList({ payload }, { call, put }) {
      const result = yield call(service.createCompaniesList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCreateCompaniesList',
        result,
      });
      return result;
    },
    *updateCompaniesList({ payload }, { call, put }) {
      const result = yield call(service.updateCompaniesList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveupdateCompaniesList',
        result,
      });
      return result;
    },
    *deleteCompaniesList({ payload }, { call, put }) {
      const result = yield call(service.deleteCompaniesList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'savedeleteCompaniesList',
        result,
      });
      return result;
    },
    //异步创建部门列表
    *createCompaniesDepartmentsList({ payload }, { call, put }) {
      const result = yield call(service.createCompaniesDepartmentsList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCreateCompaniesDepartmentsList',
        result,
      });
      return result;
    },
    *updateCompaniesDepartmentsList({ payload }, { call, put }) {
      const result = yield call(service.updateCompaniesDepartmentsList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveupdateCompaniesDepartmentsList',
        result,
      });
      return result;
    },
    *deleteCompaniesDepartmentsList({ payload }, { call, put }) {
      const result = yield call(service.deleteCompaniesDepartmentsList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'savedeleteCompaniesDepartmentsList',
        result,
      });
      return result;
    },
    //异步请求部门列表
    *getCompaniesDepartmentsList({ payload }, { call, put }) {
      const result = yield call(service.getCompaniesDepartmentsList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCompaniesDepartmentsList',
        result,
      });
      return result;
    },
    //异步请求班组列表
    *getComDepTeamsList({ payload }, { call, put }) {
      const result = yield call(service.getComDepTeamsList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveComDepTeamsList',
        result,
      });
      return result;
    },
    //异步创建班组列表
    *createComDepTeamsList({ payload }, { call, put }) {
      const result = yield call(service.createComDepTeamsList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCreateComDepTeamsList',
        result,
      });
      return result;
    },
    *updateComDepTeamsList({ payload }, { call, put }) {
      const result = yield call(service.updateComDepTeamsList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveupdateComDepTeamsList',
        result,
      });
      return result;
    },
    *deleteComDepTeamsList({ payload }, { call, put }) {
      const result = yield call(service.deleteComDepTeamsList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'savedeleteComDepTeamsList',
        result,
      });
      return result;
    },
    //异步判断上述名称是否存在
    *getExistsFlag({ payload }, { call, put }) {
      const result = yield call(service.getExistsFlag, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveExistsFlag',
        result,
      });
      return result;
    },

    // 异步请求应用列表
    *getAppList({ payload }, { call, put }) {
      const result = yield call(service.getAppList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveAppList',
        result,
      });
    },
    // 异步新增应用列表
    *createApp({ payload }, { call, put }) {
      const result = yield call(service.createApp, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createAppList',
        result,
      });
    },
    // 异步删除应用列表
    *deleteApp({ payload }, { call, put }) {
      const result = yield call(service.deleteApp, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteAppList',
          result: payload,
        });
      }
    },
    // 异步更新应用列表
    *updateApp({ payload }, { call, put }) {
      const result = yield call(service.updateApp, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateAppList',
        result,
      });
    },
    // 异步下载应用app
    *downloadApp({ payload }, { call, put }) {
      const result = yield call(service.downloadApp, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'downloadAppList',
          result,
        });
      }
    },
    // 异步下载历史记录
    *downHistoryFile({ payload }, { call, put }) {
      const result = yield call(service.downHistoryFile, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'historyFileList',
          result,
        });
      }
    },

    // 异步请求客户列表
    *getCustomerList({ payload }, { call, put }) {
      const result = yield call(service.getCustomerList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCustomerList',
        result,
      });
    },
    // 异步新增客户列表
    *createClient({ payload }, { call, put }) {
      const result = yield call(service.createClient, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createCustomerList',
        result,
      });
    },
    // 异步删除客户列表
    *deleteClient({ payload }, { call, put }) {
      const result = yield call(service.deleteClient, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteCustomerList',
          result,
          payload,
        });
      }
    },

    // 异步更新客户列表
    *updateClient({ payload }, { call, put }) {
      const result = yield call(service.updateClient, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateCustomerList',
        result,
      });
    },

    //异步请求刀具列表
    *getKnifesList({ payload }, { call, put }) {
      const result = yield call(service.getKnifesList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveKnifesList',
        result,
      });
      return result;
    },
    //异步创建刀具列表
    *createKnifes({ payload }, { call, put }) {
      const result = yield call(service.createKnifes, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createKnifesList',
        result,
      });
      return result;
    },
    //异步更新刀具列表
    *updateKnifes({ payload }, { call, put }) {
      const result = yield call(service.updateKnifes, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateKnifesList',
        result,
      });
      return result;
    },
    //异步删除刀具列表
    *deleteKnifes({ payload }, { call, put }) {
      const result = yield call(service.deleteKnifes, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'deleteKnifesList',
        result,
      });
      return result;
    },
    //异步检查刀具是否存在
    *checkKnifes({ payload }, { call, put }) {
      const result = yield call(service.checkKnifes, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'checkKnifesList',
        result,
      });
      return result;
    },
    //异步检查企业应用是否存在
    *checkApplications({ payload }, { call, put }) {
      const result = yield call(service.checkApplications, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      return result;
    },

    //异步请求刀具类型列表
    *getKnifesTypeList({ payload }, { call, put }) {
      const result = yield call(service.getKnifesTypeList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveKnifesTypeList',
        result,
      });
      return result;
    },
    //异步新增刀具类型列表
    *createKnifesType({ payload }, { call, put }) {
      const result = yield call(service.createKnifesType, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createKnifesTypeList',
        result,
      });
      return result;
    },
    //异步更新刀具类型列表
    *updateKnifesType({ payload }, { call, put }) {
      const result = yield call(service.updateKnifesType, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateKnifesTypeList',
        result,
      });
      return result;
    },
    //异步删除刀具类型列表
    *deleteKnifesType({ payload }, { call, put }) {
      const result = yield call(service.deleteKnifesType, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'deleteKnifesTypeList',
        result: payload,
      });
      return result;
    },
    //异步检查刀具类型
    *checkKnifesType({ payload }, { call, put }) {
      const result = yield call(service.checkKnifesType, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'checkKnifesTypeList',
        result: payload,
      });
      return result;
    },

    //异步请求切削材质列表
    *getKnifesMaterialsList({ payload }, { call, put }) {
      const result = yield call(service.getKnifesMaterialsList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveKnifesMaterialsList',
        result,
      });
      return result;
    },
    //异步新增切削材质列表
    *createKnifesMaterials({ payload }, { call, put }) {
      const result = yield call(service.createKnifesMaterials, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createKnifesMaterialsList',
        result,
      });
      return result;
    },
    //异步更新切削材质列表
    *updateKnifesMaterials({ payload }, { call, put }) {
      const result = yield call(service.updateKnifesMaterials, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateKnifesMaterialsList',
        result,
      });
      return result;
    },
    //异步删除切削材质列表
    *deleteKnifesMaterials({ payload }, { call, put }) {
      const result = yield call(service.deleteKnifesMaterials, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'deleteKnifesMaterialsList',
        result: payload,
      });
      return result;
    },
    //异步检查切削材质
    *checkKnifesMaterials({ payload }, { call, put }) {
      const result = yield call(service.checkKnifesMaterials, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'checkKnifesMaterialsList',
        result: payload,
      });
      return result;
    },

    // 异步请求设备分类列表
    *getDeviceTypeList({ payload }, { call, put }) {
      const result = yield call(service.getDeviceTypeList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceTypeList',
        result,
      });
    },
    // 异步新增设备分类列表
    *createDeviceType({ payload }, { call, put }) {
      const result = yield call(service.createDeviceType, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createDeviceTypeList',
        result,
      });
    },
    // 异步更新设备分类列表
    *updateDeviceType({ payload }, { call, put }) {
      const result = yield call(service.updateDeviceType, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'updateDeviceTypeList',
          result,
        });
        return result;
      }
    },
    // 异步删除设备分类列表
    *deleteDeviceType({ payload }, { call, put }) {
      const result = yield call(service.deleteDeviceType, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteDeviceTypeList',
          result: payload,
        });
      }
    },

    // 异步请求设备分组列表
    *getDeviceGroupsList({ payload }, { call, put }) {
      const result = yield call(service.getDeviceGroupsList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceGroupsList',
        result,
      });
      return result;
    },
    // 异步新增设备分组列表
    *createDeviceGroups({ payload }, { call, put }) {
      const result = yield call(service.createDeviceGroups, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createDeviceGroupsList',
        result,
      });
      return result;
    },
    // 异步更新设备分组列表
    *updateDeviceGroups({ payload }, { call, put }) {
      const result = yield call(service.updateDeviceGroups, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'updateDeviceGroupsList',
          result,
        });
        return result;
      }
    },
    // 异步删除设备分组列表
    *deleteDeviceGroups({ payload }, { call, put }) {
      const result = yield call(service.deleteDeviceGroups, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteDeviceGroupsList',
          result: payload,
        });
      }
      return result;
    },

    // 异步设备上下线
    *changeDeviceStatus({ payload }, { call, put }) {
      const result = yield call(service.changeDeviceStatus, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'changeDeviceStatusList',
          result,
        });
        return result;
      }
    },

    // 设备最大数量接口
    *getDeviceMaxCount({ payload }, { call, put }) {
      const result = yield call(service.getDeviceMaxCount, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceMaxCount',
        result,
      });
    },

    // 异步请求设备列表
    // 设备状态统计列表
    *getDeviceStatusCount({ payload }, { call, put }) {
      const result = yield call(service.getDeviceStatusCount, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceStatusCount',
        result,
      });
    },
    // 设备状态列表
    *getDeviceStatusList({ payload }, { call, put }) {
      const result = yield call(service.getDeviceStatusList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      yield put({
        type: 'saveDeviceStatusList',
        data,
      });
    },
    // 设备分页列表
    *getDeviceList({ payload }, { call, put }) {
      const result = yield call(service.getDeviceList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      const count = result.count;
      yield put({
        type: 'saveDeviceList',
        data,
        count,
      });
      return result;
    },

    // 异步请求设备任务状态数据
    *getDeviceStatus({ payload }, { call, put }) {
      const result = yield call(service.getDeviceStatus, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceStatus',
        result,
      });
    },
    // 异步请求设备累计时间数据
    *getDeviceTotalTime({ payload }, { call, put }) {
      const result = yield call(service.getDeviceTotalTime, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceTotalTime',
        result,
      });
    },
    // 异步请求设备详情数据
    *getDeviceDetail({ payload }, { call, put }) {
      const result = yield call(service.getDeviceDetail, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceDetail',
        result,
      });
    },

    // 异步新增设备列表
    *createDevice({ payload }, { call, put }) {
      const result = yield call(service.createDevice, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createDeviceList',
        result,
      });
    },
    // 异步删除设备列表
    *deleteDevice({ payload }, { call, put }) {
      const result = yield call(service.deleteDevice, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteDeviceList',
          result,
          payload,
        });
      }
    },
    // 异步更新设备列表
    *updateDevice({ payload }, { call, put }) {
      const result = yield call(service.updateDevice, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateDeviceList',
        result,
      });
    },
    // 异步请求全部设备列表（含设备模型）
    *getAllDeviceModels({ payload }, { call, put }) {
      const result = yield call(service.getAllDeviceModels, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'allDeviceModelsList',
        result,
      });
      return result;
    },
    //异步请求设备模型数量统计
    *getDeviceModelsList({ payload }, { call, put }) {
      const result = yield call(service.getDeviceModelsList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'deviceModelsList',
        result,
      });
      return result;
    },

    //设备控制器列表
    *getDevicesControllersList({ payload }, { call, put }) {
      const result = yield call(service.getDevicesControllersList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'devicesControllersList',
        result,
      });
      return result;
    },
    *createDeviceControllers({ payload }, { call, put }) {
      const result = yield call(service.createDeviceControllers, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createDeviceControllersList',
        result,
      });
      return result;
    },
    *deleteDeviceControllers({ payload }, { call, put }) {
      const result = yield call(service.deleteDeviceControllers, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'deleteDeviceControllersList',
        result,
      });
      return result;
    },
    *updateDeviceControllers({ payload }, { call, put }) {
      const result = yield call(service.updateDeviceControllers, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateDeviceControllersList',
        result,
      });
      return result;
    },
    //控制器通道列表
    *getControllersChannelsList({ payload }, { call, put }) {
      const result = yield call(service.getControllersChannelsList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'controllersChannelsList',
        result,
      });
      return result;
    },
    *createControllersChannels({ payload }, { call, put }) {
      const result = yield call(service.createControllersChannels, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createControllersChannelsList',
        result,
      });
      return result;
    },
    *deleteControllersChannels({ payload }, { call, put }) {
      const result = yield call(service.deleteControllersChannels, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'deleteControllersChannelsList',
        result,
      });
      return result;
    },
    *updateControllersChannels({ payload }, { call, put }) {
      const result = yield call(service.updateControllersChannels, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateControllersChannelsList',
        result,
      });
      return result;
    },

    // 异步请求可用的生产单元列表（订单任务）
    *getUnitsUnUsedList({ payload }, { call, put }) {
      const result = yield call(service.getUnitsUnUsedList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveUnitsUnusedList',
        result,
      });
    },
    // 异步请求所有设备列表（产线设备）
    *getDeviceNameList({ payload }, { call, put }) {
      const result = yield call(service.getDeviceNameList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceNameList',
        result,
      });
    },
    // 异步请求车间可用设备列表（产线设备）
    *getShopFreeDevices({ payload }, { call, put }) {
      const result = yield call(service.getShopFreeDevices, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveShopFreeDevices',
        result,
      });
      return result;
    },
    // 异步请求所有车间和对应车间的游离设备
    *getWorkShopDevice({ payload }, { call, put }) {
      const result = yield call(service.getWorkShopDevice, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      const count = result.count;
      yield put({
        type: 'saveWorkShopDeviceList',
        data,
        count,
      });
    },


    // 获取单个控制器数据
    // 时间利用率 TimeRate
    *getCTLTimeRate({ payload }, { call, put }) {
      const result = yield call(service.getCTLTimeRate, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCTLTimeRate',
        result,
      });
    },
    // 运行时间分布 Distribute
    *getCTLDistribute({ payload }, { call, put }) {
      const result = yield call(service.getCTLDistribute, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCTLDistribute',
        result,
      });
    },
    // 获取控制器系统信息 SystemInfo
    *getCTLSystemInfo({ payload }, { call, put }) {
      const result = yield call(service.getCTLSystemInfo, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCTLSystemInfo',
        result,
      });
      return result;
    },
    // 获取控制器自定义数据 CustomData
    *getCTLCustomData({ payload }, { call, put }) {
      const result = yield call(service.getCTLCustomData, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCTLCustomData',
        result,
      });
    },
    // 获取控制器实时状态 TimeStatus
    *getCTLTimeStatus({ payload }, { call, put }) {
      const result = yield call(service.getCTLTimeStatus, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCTLTimeStatus',
        result,
      });
    },

    // 异步请求产线列表
    *getProductLIneList({ payload }, { call, put }) {
      const result = yield call(service.getProductLIneList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveProductLIneList',
        result,
      });
      return result
    },
    *getProductLIneInfo({ payload }, { call, put }) {
      const result = yield call(service.getProductLIneInfo, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveProductLIneListInfo',
        result,
      });
      return result
    },
    // 异步新增产线列表
    *createProductLIne({ payload }, { call, put }) {
      const result = yield call(service.createProductLIne, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createProductLIneList',
        result,
      });
      return result;
    },
    // 异步更新产线列表
    *updateProductLIne({ payload }, { call, put }) {
      const result = yield call(service.updateProductLIne, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateProductLIneList',
        result,
      });
    },
    // 异步删除产线列表
    *deleteProductLIne({ payload }, { call, put }) {
      const result = yield call(service.deleteProductLIne, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteProductLIneList',
          result,
          payload,
        });
      }
      return result
    },
    // 异步请求产线设备列表
    *getProductLIneDevicesList({ payload }, { call, put }) {
      const result = yield call(service.getProductLIneDevicesList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'saveProductLIneDevicesList',
          result,
          payload,
        });
      }
      return result
    },
    // 异步创建产线设备
    *createProductLIneDevices({ payload }, { call, put }) {
      const result = yield call(service.createProductLIneDevices, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createProductLIneDevices',
        result,
      });
      return result
    },
    // 异步请求产线计数模型列表
    *getProductLIneCountingModeList({ payload }, { call, put }) {
      const result = yield call(service.getProductLIneCountingModeList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'saveProductLIneCountingModeList',
          result,
          payload,
        });
      }
      return result
    },
    // 异步创建产线计数模型
    *createProductLIneCountingModeList({ payload }, { call, put }) {
      const result = yield call(service.createProductLIneCountingModeList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'createProductLIneCountingMode',
          result,
          payload,
        });
      }
      return result
    },
    // 异步更新产线计数
    *updateProductLIneCountingModeList({ payload }, { call, put }) {
      const result = yield call(service.updateProductLIneCountingModeList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'updateProductLIneCountingMode',
          result,
          payload,
        });
      }
      return result
    },
    // 异步删除产线计数
    *deleteProductLIneCountingModeList({ payload }, { call, put }) {
      const result = yield call(service.deleteProductLIneCountingModeList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteProductLIneCountingMode',
          result,
          payload,
        });
      }
      return result
    },
    // 异步获取产线日志列表
    *getProductLineLogs({ payload }, { call, put }) {
      const result = yield call(service.getProductLineLogs, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'saveProductLineLogsList',
          result,
          payload,
        });
      }
      return result
    },
    // 异步获取产线累计运行状态列表
    *getProductLineOpeStatus({ payload }, { call, put }) {
      const result = yield call(service.getProductLineOpeStatus, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'saveProductLineOpeStatus',
          result,
          payload,
        });
      }
      return result
    },
    // 异步请求设备计数模型列表
    *getDeviceCountingModeList({ payload }, { call, put }) {
      const result = yield call(service.getDeviceCountingModeList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'saveDeviceCountingModeList',
          result,
          payload,
        });
      }
      return result
    },
    // 异步创建设备计数模型
    *createDeviceCountingModeList({ payload }, { call, put }) {
      const result = yield call(service.createDeviceCountingModeList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'createDeviceCountingMode',
          result,
          payload,
        });
      }
      return result
    },
    // 异步更新设备计数
    *updateDeviceCountingModeList({ payload }, { call, put }) {
      const result = yield call(service.updateDeviceCountingModeList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'updateDeviceCountingMode',
          result,
          payload,
        });
      }
      return result
    },
    // 异步删除设备计数
    *deleteDeviceCountingModeList({ payload }, { call, put }) {
      const result = yield call(service.deleteDeviceCountingModeList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteDeviceCountingMode',
          result,
          payload,
        });
      }
      return result
    },


    // 异步请求产品列表
    *getProductionNameList({ payload }, { call, put }) {
      const result = yield call(service.getProductionNameList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveProductionNameList',
        result,
      });
    },
    // 异步请求产品列表
    *getProductionList({ payload }, { call, put }) {
      const result = yield call(service.getProductionList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      const productCount = result.count;
      yield put({
        type: 'saveProductionList',
        data,
        productCount,
      });
    },
    // 异步新增产品列表
    *createProduction({ payload }, { call, put }) {
      yield call(service.createProduction, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
    },
    // 异步更新产品列表
    *updateProduction({ payload }, { call, put }) {
      yield call(service.updateProduction, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
    },
    // 异步删除产品列表
    *deleteProduction({ payload }, { call, put }) {
      const result = yield call(service.deleteProduction, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteProductionList',
          result,
          payload,
        });
      }
    },
    // 异步查看产品列表
    *detailProduction({ payload }, { call, put }) {
      const result = yield call(service.detailProduction, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'detailProductionList',
        result,
      });
    },
    // 异步查看产品累计数据
    *totalProduction({ payload }, { call, put }) {
      const result = yield call(service.totalProduction, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'totalProductionData',
        result,
      });
    },
    // 异步获取产品日志数据
    *getDailyProductList({ payload }, { call, put }) {
      const result = yield call(service.getDailyProductList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      const count = result.count;
      yield put({
        type: 'saveDailyProductList',
        data,
        count,
      });
    },

    //异步获取产品不良原因列表
    *getRejectReasonList({ payload }, { call, put }) {
      const result = yield call(service.getRejectReasonList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveRejectReasonList',
        result,
      });
      return result;
    },
    *creaetRejectReason({ payload }, { call, put }) {
      const result = yield call(service.creaetRejectReason, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'creaetRejectReasonList',
        result,
      });
      return result;
    },
    *updateRejectReason({ payload }, { call, put }) {
      const result = yield call(service.updateRejectReason, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateRejectReasonList',
        result,
      });
      return result;
    },
    *deleteRejectReason({ payload }, { call, put }) {
      const result = yield call(service.deleteRejectReason, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'deleteRejectReasonList',
        result,
      });
      return result;
    },
    *checkRejectReason({ payload }, { call, put }) {
      const result = yield call(service.checkRejectReason, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'checkRejectReasonList',
        result,
      });
      return result;
    },
    // 要导出的数据
    *getDailyProductData({ payload }, { call, put }) {
      const result = yield call(service.getDailyProductList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      const page = result.page;
      const size = result.size;
      const count = result.count;
      yield put({
        type: 'saveDailyProductData',
        data,
        page,
        size,
        count,
      });
    },
    // 异步请求测量要求列表数据
    *getMeasurementRequirementData({ payload }, { call, put }) {
      const result = yield call(service.getMeasurementRequirement, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveMeasurementRequirementData',
        result
      });
      return result;
    },
    // 异步请求零件图号测量要求列表数据
    *getModMeasurementRequirement({ payload }, { call, put }) {

      const result = yield call(service.getModMeasurementRequirement, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      // yield put({
      //   type: 'saveMeasurementRequirementData',
      //   result
      // });
      return result;
    },
    // 异步新增测量要求
    *createMeasurementRequirement({ payload }, { call, put }) {
      const result = yield call(service.createMeasurementRequirement, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createMeasurementRequirementList',
        result,
      });
    },
    // 异步新增零件图号测量要求
    *createModMeasurementRequirement({ payload }, { call, put }) {
      const result = yield call(service.createModMeasurementRequirement, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      // yield put({
      //   type: 'createMeasurementRequirementList',
      //   result,
      // });
      return result;

    },
    // 异步更新测量要求
    *updateMeasurementRequirement({ payload }, { call, put }) {
      const result = yield call(service.updateMeasurementRequirement, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      // yield put({
      //   type: 'updateMeasurementRequirement',
      //   result,
      // });
    },
    // 异步更新零件图号测量要求
    *updateModMeasurementRequirement({ payload }, { call, put }) {
      const result = yield call(service.updateModMeasurementRequirement, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      // yield put({
      //   type: 'updateMeasurementRequirement',
      //   result,
      // });
      return result;
    },
    // 异步删除测量要求
    *deleteMeasurementRequirement({ payload }, { call, put }) {
      const result = yield call(service.deleteMeasurementRequirement, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'deleteMeasurementRequirementList',
        result,
      });
      return result;
    },
    // 异步删除零件图号测量要求
    *deleteModMeasurementRequirement({ payload }, { call, put }) {
      const result = yield call(service.deleteModMeasurementRequirement, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      // yield put({
      //   type: 'deleteMeasurementRequirementList',
      //   result,
      // });
      return result;
    },
    // 异步请求图号列表
    *getComponentList({ payload }, { call, put }) {
      const result = yield call(service.getComponentList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveComponentList',
        result,
      });
      return result;
    },
    // 异步新增图号列表
    *createComponent({ payload }, { call, put }) {
      const result = yield call(service.createComponent, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createComponentList',
        result,
      });
    },
    // 异步更新图号列表
    *updateComponent({ payload }, { call, put }) {
      const result = yield call(service.updateComponent, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateComponentList',
        result,
      });
    },
    // 异步删除产品图纸
    *deleteUplodeFile({ payload }, { call, put }) {
      const result = yield call(service.deleteUplodeFile, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'deleteUplodeFileList',
        result,
      });
      return result;
    },

    // 异步请求刀具清单列表
    *getToolList({ payload }, { call, put }) {
      const result = yield call(service.getToolList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveToolList',
        result,
      });
      return result;
    },
    // 异步创建刀具清单列表
    *createToolList({ payload }, { call, put }) {
      const result = yield call(service.createToolList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createToolList',
        result,
      });
      return result;
    },
    // 异步更新刀具清单列表
    *updateToolList({ payload }, { call, put }) {
      const result = yield call(service.updateToolList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateToolList',
        result,
      });
      return result;
    },
    // 异步删除刀具清单列表
    *deleteToolList({ payload }, { call, put }) {
      const result = yield call(service.deleteToolList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'deleteToolList',
        result,
      });
      return result;
    },

    // 异步下载文件材料列表
    *downloadFile({ payload }, { call, put }) {
      const result = yield call(service.downloadFile, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'downloadFileList',
          result,
        });
      }
    },
    // 异步删除文件材料列表
    *deleteFile({ payload }, { call, put }) {
      const result = yield call(service.deleteFile, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteFileList',
          result: payload,
        });
      }
    },
    // 异步删除零件图号工序
    *deleteProcess({ payload }, { call, put }) {
      const result = yield call(service.deleteProcess, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteProcessList',
          result: payload,
        });
      }
    },

    // 异步请求产品所有分类列表
    *getProductTypesList({ payload }, { call, put }) {
      const result = yield call(service.getProductTypesList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      const typesCount = result.count;
      yield put({
        type: 'saveProductTypesList',
        data,
        typesCount,
      });
    },
    // 异步请求产品一级分类列表
    *getCategoryList({ payload }, { call, put }) {
      const result = yield call(service.getCategoryList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCategoryList',
        result,
      });
    },
    // 异步请求产品二级分类列表
    *getSCategoryList({ payload }, { call, put }) {
      const result = yield call(service.getSCategoryList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveSCategoryList',
        result,
      });
    },
    // 异步新增产品分类列表
    *createProductType({ payload }, { call, put }) {
      yield call(service.createProductType, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
    },
    // 异步更新产品分类列表
    *updateProductType({ payload }, { call, put }) {
      yield call(service.updateProductType, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
    },
    // 异步删除产品分类列表
    *deleteProductType({ payload }, { call, put }) {
      const result = yield call(service.deleteProductType, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteProductTypeList',
          result,
          payload,
        });
      }
    },

    // 异步请求车间列表
    *getWorkShopList({ payload }, { call, put }) {
      const result = yield call(service.getWorkShopList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveWorkShopList',
        result,
      });
    },
    // 异步新增车间列表
    *createWorkShop({ payload }, { call, put }) {
      const result = yield call(service.createWorkShop, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createWorkShopList',
        result,
      });
    },
    // 异步更新车间列表
    *updateWorkShop({ payload }, { call, put }) {
      const result = yield call(service.updateWorkShop, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateWorkShopList',
        result,
      });
    },
    // 异步删除车间列表
    *deleteWorkShop({ payload }, { call, put }) {
      const result = yield call(service.deleteWorkShop, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteWorkShopList',
          result,
          payload,
        });
      }
    },

    // 异步请求网关实时状态列表
    *getNetStatusList({ payload }, { call, put }) {
      const result = yield call(service.getNetStatusList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveNetStatusList',
        result,
      });
    },
    // 异步请求网关类型列表
    *getNetTypeList({ payload }, { call, put }) {
      const result = yield call(service.getNetTypeList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveNetTypeList',
        result,
      });
    },
    // 异步请求网关详情列表
    *getNetTypeInfo({ payload }, { call, put }) {
      const result = yield call(service.getNetTypeInfo, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveNetTypeInfo',
        result,
      });
    },
    // 异步请求网关设备列表
    *getNetDeviceList({ payload }, { call, put }) {
      const result = yield call(service.getNetDeviceList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      const deviceCount = result.count;
      yield put({
        type: 'saveNetDeviceList',
        data,
        deviceCount,
      });
    },
    // 异步请求网关所有设备列表
    *getNetDeviceAllList({ payload }, { call, put }) {
      const result = yield call(service.getNetDeviceList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveNetDeviceAllList',
        result,
      });
    },

    // 异步请求验证制造商名称重复
    *VendorNameTest({ payload }, { call, put }) {
      const result = yield call(service.VendorNameTest, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'VendorNameTestRes',
          result,
        });
      }
    },

    // 异步请求验证大屏名称重复
    *ScreenNameTest({ payload }, { call, put }) {
      const result = yield call(service.ScreenNameTest, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'ScreenNameTestRes',
          result,
        });
      }
    },

    // 异步请求验证客户姓名重复
    *ClientNameTest({ payload }, { call, put }) {
      const result = yield call(service.ClientNameTest, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'ClientNameTestRes',
          result,
        });
      }
    },

    // 异步请求验证应用账户名称重复
    *AppNameTest({ payload }, { call, put }) {
      const result = yield call(service.AppNameTest, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'AppNameTestRes',
          result,
        });
      }
    },

    // 异步请求验证人员员工姓名,工号,手机号重复
    *UserCheck({ payload }, { call, put }) {
      const result = yield call(service.UserCheck, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'UserCheckRes',
          result,
        });
      }
    },
    // 异步请求验证车间名称重复
    *WSNameTest({ payload }, { call, put }) {
      const result = yield call(service.WSNameTest, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'WSNameTestRes',
          result,
        });
      }
    },
    // 异步请求验证产线名称重复
    *PLNameTest({ payload }, { call, put }) {
      const result = yield call(service.PLNameTest, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'PLNameTestRes',
          result,
        });
      }
    },
    // 异步请求验证设备编号重复
    *DevNumTest({ payload }, { call, put }) {
      const result = yield call(service.DevNumTest, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'DevNumTestRes',
          result,
        });
      }
    },

    // 异步请求设备分类名称重复
    *DevTypeNameTest({ payload }, { call, put }) {
      const result = yield call(service.DevTypeNameTest, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'DevTypeNameTestRes',
          result,
        });
      }
    },
    // 异步请求产品分类名称重复
    *ProTypeNameTest({ payload }, { call, put }) {
      const result = yield call(service.ProTypeNameTest, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'ProTypeNameTestRes',
          result,
        });
      }
    },

    // 异步请求验证产品图号和产品名称重复
    *ProNumTest({ payload }, { call, put }) {
      const result = yield call(service.ProNumTest, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'ProNumTestRes',
          result,
        });
      }
    },
    *ProNameTest({ payload }, { call, put }) {
      const result = yield call(service.ProNameTest, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'ProNameTestRes',
          result,
        });
      }
    },

    /* 人员、订单、产品、客户数据查重导入导出 */
    *handleCheck({ payload }, { call, put }) {
      const result = yield call(service.handleCheck, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'checkData',
        result,
      });
    },
    *handleImport({ payload }, { call, put }) {
      const result = yield call(service.handleImport, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'importData',
        result,
      });
    },
    *handleExport({ payload }, { call, put }) {
      const result = yield call(service.handleExport, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'exportData',
        result,
      });
    },
    /* 设备组网文件数据导出 */
    *handleExportCNC({ payload }, { call, put }) {
      const result = yield call(service.handleExportCNC, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'exportCNCData',
          result,
        });
      }
    },

    // 异步请求大屏列表
    *getScreenList({ payload }, { call, put }) {
      const result = yield call(service.getScreenList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveScreenList',
        result,
      });
    },
    // 异步新增大屏
    *createScreen({ payload }, { call, put }) {
      const result = yield call(service.createScreen, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createScreenList',
        result,
      });
    },
    // 异步更新大屏
    *updateScreen({ payload }, { call, put }) {
      const result = yield call(service.updateScreen, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateScreenList',
        result,
      });
    },
    // 异步删除大屏
    *deleteScreen({ payload }, { call, put }) {
      const result = yield call(service.deleteScreen, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteScreenList',
          result: payload,
        });
      }
    },
    // 获取云南大屏的生产单元数据
    *getScreenProcessInfo({ payload }, { call, put }) {
      const result = yield call(service.getScreenProcessInfo, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveScreenProcessData',
        result,
      });
    },

    // 所有企业列表
    *getEnterpriseList({ payload }, { call, put }) {
      const result = yield call(service.getEnterpriseList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const enterCount = result.count;
      const data = result.results;
      for (const item of data) {
        if (item.owner) {
          let account = yield call(service.getAccountData, item.owner);
          item.username = account.username;
        } else {
          item.username = "";
        }
      }
      /*
      data.forEach(item => {
        new Promise((resolve, reject) => {
          if(item.owner){
            resolve(service.getAccountData(item.owner));
          }else{
            reject();
          }
        }).then(success => {
          item.username= success.username;
        }).catch(()=>{
          item.username= "";
        });
      });
      */
      yield put({
        type: 'saveEnterpriseList',
        data,
        enterCount
      });
    },
    // 异步新增企业
    *createEnterprise({ payload }, { call, put }) {
      const result = yield call(service.createEnterprise, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      let account = yield call(service.getAccountData, result.owner);
      result.username = account.username;
      yield put({
        type: 'createEnterpriseList',
        result,
      });
    },
    // 异步更新企业
    *updateEnterprise({ payload }, { call, put }) {
      const result = yield call(service.updateEnterprise, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      let account = yield call(service.getAccountData, result.owner);
      result.username = account.username;
      yield put({
        type: 'updateEnterpriseList',
        result,
      });
    },
    // 异步删除企业
    *deleteEnterprise({ payload }, { call, put }) {
      const result = yield call(service.deleteEnterprise, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteEnterpriseList',
          result: payload,
        });
      }
    },
    // 企业名称重复验证
    *enterpriseCheck({ payload }, { call, put }) {
      const result = yield call(service.enterpriseCheck, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'enterpriseCheckRes',
          result,
        });
      }
    },

    // 所有账户列表
    *getAccountList({ payload }, { call, put }) {
      const result = yield call(service.getAccountList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      const accountCount = result.count;
      yield put({
        type: 'saveAccountList',
        data,
        accountCount
      });
    },
    // 异步新增账户
    *createAccount({ payload }, { call, put }) {
      const result = yield call(service.createAccount, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createAccountList',
        result,
      });
    },
    // 异步更新账户
    *updateAccount({ payload }, { call, put }) {
      const result = yield call(service.updateAccount, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateAccountList',
        result,
      });
    },
    // 异步删除账户
    *deleteAccount({ payload }, { call, put }) {
      const result = yield call(service.deleteAccount, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteAccountList',
          result: payload,
        });
      }
    },
    // 企业名称重复验证
    *accountCheck({ payload }, { call, put }) {
      const result = yield call(service.accountCheck, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'accountCheckRes',
          result,
        });
      }
    },

    //异步获取班次列表信息
    *getShiftsList({ payload }, { call, put }) {
      const result = yield call(service.getShiftsList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveShiftsList',
        result
      });
      return result;
    },
    // 异步创建班次列表信息
    *createShifts({ payload }, { call, put }) {
      const result = yield call(service.createShifts, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createShiftsList',
        result,
      });
      return result;
    },
    // 异步更新班次列表信息
    *updateShifts({ payload }, { call, put }) {
      const result = yield call(service.updateShifts, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateShiftsList',
        result,
      });
      return result;
    },
    // 异步删除班次列表信息
    *deleteShifts({ payload }, { call, put }) {
      const result = yield call(service.deleteShifts, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteShiftsList',
          result: payload,
        });
        return result;
      }
    },

    // 制造商接口
    // 获取所有的制造商列表
    *getManufacturerList({ payload }, { call, put }) {
      const result = yield call(service.getManufacturerList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveManufacturerList',
        result,
      });
    },
    // 制造商获取当前设备分布的企业
    *getEnterpriseBelongList({ payload }, { call, put }) {
      const result = yield call(service.getEnterpriseBelongList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveEnterpriseBelongList',
        result,
      });
    },
    // 制造商所选企业设备统计
    *getCompanyDeviceCount({ payload }, { call, put }) {
      const result = yield call(service.getCompanyDeviceCount, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCompanyDeviceCount',
        result,
      });
    },
    // 制造商所选企业设备时间利用率
    *getCompanyDeviceRatio({ payload }, { call, put }) {
      const result = yield call(service.getCompanyDeviceRatio, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCompanyDeviceRatio',
        result,
      });
    },
    // 制造商所选企业设备趋势
    *getCompanyDeviceStat({ payload }, { call, put }) {
      const result = yield call(service.getCompanyDeviceStat, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCompanyDeviceStat',
        result,
      });
    },
    // 制造商所选企业长期离线设备
    *getCompanyDeviceOffline({ payload }, { call, put }) {
      const result = yield call(service.getCompanyDeviceOffline, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCompanyDeviceOffline',
        result,
      });
    },
    // 重点维护严重报警设备列表（实时）
    *getRealTimeSeriousAlarms({ payload }, { call, put }) {
      const result = yield call(service.getRealTimeSeriousAlarms, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveRealTimeSeriousAlarms',
        result,
      });
    },

    // 制造商所选企业设备故障报警统计
    *getCompanyDeviceDefault({ payload }, { call, put }) {
      const result = yield call(service.getCompanyDeviceDefault, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCompanyDeviceDefault',
        result,
      });
    },
    // 制造商所选企业设备报警类型、次数统计
    *getCompanyAlarmData({ payload }, { call, put }) {
      const result = yield call(service.getCompanyAlarmData, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCompanyAlarmData',
        result,
      });
    },
    // 制造商所选企业设备故障报警四个列表
    *getCompanyDefaultTabOne({ payload }, { call, put }) {
      const result = yield call(service.getCompanyDefaultTabOne, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCompanyDefaultTabOne',
        result,
      });
    },
    *getCompanyDefaultTabTwo({ payload }, { call, put }) {
      const result = yield call(service.getCompanyDefaultTabTwo, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCompanyDefaultTabTwo',
        result,
      });
    },
    *getCompanyDefaultTabThr({ payload }, { call, put }) {
      const result = yield call(service.getCompanyDefaultTabThr, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCompanyDefaultTabThr',
        result,
      });
    },
    *getCompanyDefaultTabFour({ payload }, { call, put }) {
      const result = yield call(service.getCompanyDefaultTabFour, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCompanyDefaultTabFour',
        result,
      });
    },

    // 异步获取地图json数据
    *getGeoJsonData({ payload }, { call, put }) {
      const result = yield call(service.getGeoJsonData, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveGeoJsonData',
        result,
      });
    },
    // 异步获取制造商设备区域数量分布
    *getDeviceDisCount({ payload }, { call, put }) {
      const result = yield call(service.getDeviceDisCount, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceDisCount',
        result,
      });
    },
    // 异步获取制造商设备类型数量占比
    *getDeviceNumberRatio({ payload }, { call, put }) {
      const result = yield call(service.getDeviceNumberRatio, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceNumberRatio',
        result,
      });
    },

    // 异步获取远程请求列表
    *getRemoteConnectList({ payload }, { call, put }) {
      const result = yield call(service.getRemoteConnectList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.data;
      const listCount = result.count;
      yield put({
        type: 'saveRemoteConnectList',
        data,
        listCount
      });
    },
    // 异步获取远程请求记录
    *getRemoteConnectLog({ payload }, { call, put }) {
      const result = yield call(service.getRemoteConnectLog, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.data;
      const recordCount = result.count;
      yield put({
        type: 'saveRemoteConnectLog',
        data,
        recordCount
      });
    },
    // 异步提交远程请求操作
    *postRemoteConnectData({ payload }, { call, put }) {
      const result = yield call(service.postRemoteConnectData, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveRemoteConnectData',
        result,
      });
    },
  },

  // 同步方法，主要讲数据更新到state中
  reducers: {
    // 客户 Customer
    saveCustomerList(state, { result: CustomerData }) {
      return {
        ...state,
        CustomerData,
      };
    },
    createCustomerList(state, { result: CustomerData }) {
      state.CustomerData = [CustomerData].concat(state.CustomerData);
      return { ...state };
    },
    updateCustomerList(state, { result }) {
      state.CustomerData = state.CustomerData.map(item => {
        return item.id === result.id ? result : item;
      });
      return { ...state };
    },
    deleteCustomerList(state, { result: CustomerDeleteMes, payload }) {
      const newCustomerData =
        CustomerDeleteMes === 204
          ? state.CustomerData.filter(item => item.id !== payload)
          : state.CustomerData;
      return {
        ...state,
        CustomerData: newCustomerData,
        CustomerDeleteMes,
      };
    },

    saveVersionTxt(state, { result: versionData }) {
      return { ...state, versionData };
    },

    // 日产量统计列表
    saveStatisticList(state, { data: StatisticData, count }) {
      return {
        ...state,
        StatisticData,
        count,
      };
    },
    // 企业所有设备历史产量数据
    saveStatisticDeviceHistory(state, { result: StatisticDeviceHisData }) {
      return { ...state, StatisticDeviceHisData };
    },
    saveProductYields(state, { result: ProductYields }) {
      return { ...state, ProductYields };
    },
    saveProductStatusYields(state, { result: ProductStatusYields }) {
      return { ...state, ProductStatusYields };
    },
    saveProductStatusYields(state, { result: ProductStatusYields }) {
      return { ...state, ProductStatusYields };
    },
    savegetProductLineTodayFinish(state, { result: ProductLineTodayFinish }) {
      return { ...state, ProductLineTodayFinish };
    },
    saveDailyProductNonTaskYields(state, { result: DailyProductNonTaskYields }) {
      return { ...state, DailyProductNonTaskYields };
    },

    // 人员分组 Person
    saveGroupsList(state, { result: GroupData }) {
      return { ...state, GroupData };
    },
    // 人员 Person
    savePersonList(state, { result: PersonData }) {
      return {
        ...state,
        PersonData,
      };
    },
    savePersonNameList(state, { result: PersonNameData }) {
      return {
        ...state,
        PersonNameData,
      };
    },
    createPersonList(state, { result: PersonData }) {
      state.PersonData = [PersonData].concat(state.PersonData);
      return { ...state };
    },
    deletePersonList(state, { result }) {
      if (result && result.id) {
        const newPersonData = state.PersonData.filter(item => item.id !== result.id);
        return {
          ...state,
          deletePersonRes: result,
          PersonData: newPersonData,
        };
      } else {
        return {
          ...state,
          deletePersonRes: result,
        };
      }
    },
    updatePersonList(state, { result }) {
      if (result && result.id) {
        state.PersonData = state.PersonData.map(item => {
          return item.id === result.id ? result : item;
        });
        return {
          ...state,
          updatePersonRes: result,
        };
      } else {
        return {
          ...state,
          updatePersonRes: result,
        };
      }
    },

    // 应用 App
    saveAppList(state, { result: AppData }) {
      return {
        ...state,
        AppData,
      };
    },
    createAppList(state, { result: AppData }) {
      state.AppData = [AppData].concat(state.AppData);
      return {
        ...state,
      };
    },
    deleteAppList(state, { result: payload }) {
      const newAppData = state.AppData.filter(item => item.id !== payload);
      return {
        ...state,
        AppData: newAppData,
      };
    },
    updateAppList(state, { result }) {
      state.AppData = state.AppData.map(item => {
        return item.id === result.id ? result : item;
      });
      return { ...state };
    },
    downloadAppList(state, { result: downloadApp }) {
      return {
        ...state,
        downloadApp,
      };
    },
    historyFileList(state, { result: downloadFiles }) {
      return {
        ...state,
        downloadFiles,
      };
    },

    // 设备 Device
    // 所有设备类型 DeviceTypeData
    saveDeviceTypeList(state, { result: DeviceTypeData }) {
      return {
        ...state,
        DeviceTypeData,
      };
    },
    // 设备分类增删改
    createDeviceTypeList(state, { result: DeviceTypeData }) {
      state.DeviceTypeData = [DeviceTypeData].concat(state.DeviceTypeData);
      return {
        ...state,
      };
    },
    updateDeviceTypeList(state, { result }) {
      state.DeviceTypeData = state.DeviceTypeData.map(item => {
        return item.id === result.id ? result : item;
      });
      return { ...state };
    },
    deleteDeviceTypeList(state, { result: payload }) {
      const newDeviceTypeData = state.DeviceTypeData.filter(item => item.id !== payload);
      return {
        ...state,
        DeviceTypeData: newDeviceTypeData,
      };
    },

    // 设备最大接入数量
    saveDeviceMaxCount(state, { result: DeviceMaxCount }) {
      return {
        ...state,
        DeviceMaxCount,
      };
    },

    // 单机设备详情状态 saveDeviceDetail
    saveDeviceDetail(state, { result: DeviceDetailData }) {
      return {
        ...state,
        DeviceDetailData,
      };
    },
    // 单机设备任务状态 saveDeviceStatus
    saveDeviceStatus(state, { result: DeviceStatusData }) {
      return {
        ...state,
        DeviceStatusData,
      };
    },
    // 单机设备累计状态 saveDeviceTotalTime
    saveDeviceTotalTime(state, { result: DeviceTotalTimeData }) {
      return {
        ...state,
        DeviceTotalTimeData,
      };
    },

    // 可用的生产单元列表
    saveUnitsUnusedList(state, { result: UnitsUnusedData }) {
      return {
        ...state,
        UnitsUnusedData,
      };
    },

    // 设备增删改查
    saveDeviceStatusCount(state, { result: DeviceCountData }) {
      return {
        ...state,
        DeviceCountData,
      };
    },
    saveDeviceStatusList(state, { data: DeviceListData }) {
      return {
        ...state,
        DeviceListData,
      };
    },
    saveDeviceList(state, { data: DeviceData, count }) {
      return {
        ...state,
        DeviceData,
        count,
      };
    },
    saveDeviceNameList(state, { result: DeviceNameData }) {
      return {
        ...state,
        DeviceNameData,
      };
    },
    saveShopFreeDevices(state, { result: DeviceFreeData }) {
      return {
        ...state,
        DeviceFreeData,
      };
    },
    saveWorkShopDeviceList(state, { data: WorkShopDeviceData, count }) {
      return {
        ...state,
        count,
        WorkShopDeviceData,
      };
    },

    createDeviceList(state, { result: DeviceResponseData }) {
      if (DeviceResponseData && DeviceResponseData.error) {
        const newDeviceData = state.DeviceData;
        return {
          ...state,
          DeviceData: newDeviceData,
          DeviceResponseData,
        };
      } else {
        const newDeviceData = [DeviceResponseData].concat(state.DeviceData);
        return {
          ...state,
          DeviceData: newDeviceData,
          DeviceResponseData,
        };
      }
    },
    deleteDeviceList(state, { result: DeviceDeleteMes, payload }) {
      if (DeviceDeleteMes && DeviceDeleteMes.error) {
        // 检验报错
        const newDeviceData = state.DeviceData;
        return {
          ...state,
          DeviceData: newDeviceData,
          DeviceDeleteMes,
        };
      } else {
        // DeviceDeleteMes.message==='ok' 或者 执行假删除
        const newDeviceData = state.DeviceData.filter(item => item.id !== payload.id);
        return {
          ...state,
          DeviceData: newDeviceData,
          DeviceDeleteMes,
        };
      }
    },
    updateDeviceList(state, { result }) {
      const DeviceUpdateMes = result.error;
      state.DeviceData = state.DeviceData.map(item => {
        return item.id === result.id ? result : item;
      });
      return {
        ...state,
        DeviceUpdateMes,
      };
    },

    // 控制器返回数据
    // 时间利用率 TimeRate
    saveCTLTimeRate(state, { result: CNCRateData }) {
      return {
        ...state,
        CNCRateData,
      };
    },
    // 运行时间分布 Distribute
    saveCTLDistribute(state, { result: CNCDistributeData }) {
      return {
        ...state,
        CNCDistributeData,
      };
    },
    // 控制器系统信息 SystemInfo
    saveCTLSystemInfo(state, { result: CNCSystemInfoData }) {
      return {
        ...state,
        CNCSystemInfoData,
      };
    },
    // 控制器自定义数据 CustomData
    saveCTLCustomData(state, { result: CNCSystemCustomData }) {
      return {
        ...state,
        CNCSystemCustomData,
      };
    },
    // 控制器实时状态 TimeStatus
    saveCTLTimeStatus(state, { result: CNCTimeStatusData }) {
      return {
        ...state,
        CNCTimeStatusData,
      };
    },

    // 产线 ProductLine
    saveProductLIneList(state, { result: ProductLIneData }) {
      return {
        ...state,
        ProductLIneData,
      };
    },
    createProductLIneList(state, { result: ProductLIneData }) {
      state.ProductLIneData = [ProductLIneData].concat(state.ProductLIneData);
      return { ...state };
    },
    updateProductLIneList(state, { result }) {
      // 修改成功还是修改失败
      if (result.error) {
        return {
          ...state,
          ProductLineUpdateMes: result.error
        };
      } else if (result.id) {
        state.ProductLIneData = state.ProductLIneData.map(item => {
          return item.id === result.id ? result : item;
        });
        return {
          ...state,
          ProductLineUpdateMes: ''
        };
      } else if (result && result[0] && result[0] == 'tasks must finished or canceled') {
        return {
          ...state,
          ProductLineUpdateMes: result[0]
        };
      }
    },
    deleteProductLIneList(state, { result: ProductLineDeleteMes, payload }) {
      if (ProductLineDeleteMes && ProductLineDeleteMes.error) {
        // 检验报错
        const newProductLIneData = state.ProductLIneData;
        return {
          ...state,
          DeviceData: newProductLIneData,
          ProductLineDeleteMes,
        };
      }
      if (ProductLineDeleteMes && ProductLineDeleteMes.message === 'ok') {
        const newProductLIneData = state.ProductLIneData.filter(item => item.id !== payload);
        return {
          ...state,
          ProductLIneData: newProductLIneData,
          ProductLineDeleteMes,
        };
      }
    },

    // 产品名称 ProductionName
    saveProductionNameList(state, { result: ProductionNameData }) {
      return {
        ...state,
        ProductionNameData,
      }
    },
    // 产品 Production
    saveProductionList(state, { data: ProductionData, productCount }) {
      return {
        ...state,
        ProductionData,
        productCount,
      };
    },
    deleteProductionList(state, { result: ProductionDeleteMes, payload }) {
      if (ProductionDeleteMes && ProductionDeleteMes.error) {
        // 检验报错
        return {
          ...state,
          ProductionDeleteMes,
        };
      }
      if (ProductionDeleteMes && ProductionDeleteMes.message === 'ok') {
        const newProductionData = state.ProductionData.filter(item => item.id !== payload);
        return {
          ...state,
          ProductionData: newProductionData,
          ProductionDeleteMes,
        };
      }
    },
    detailProductionList(state, { result: productDataOne }) {
      return {
        ...state,
        productDataOne,
      };
    },
    totalProductionData(state, { result: productTotalData }) {
      return {
        ...state,
        productTotalData,
      };
    },

    // 产品日志列表和数据
    saveDailyProductList(state, { data: DailyProductList, count }) {
      return {
        ...state,
        DailyProductList,
        count,
      };
    },
    saveDailyProductData(state, { data: DailyProductData, page, size, count }) {
      return {
        ...state,
        DailyProductData,
        count,
        page,
        size,
      };
    },
    saveMeasurementRequirementData(state, { result: MeasurementRequirementData }) {
      return {
        ...state,
        MeasurementRequirementData
      };
    },
    createMeasurementRequirement(state, { result: MeasurementRequirementData }) {
      state.MeasurementRequirementData = [MeasurementRequirementData].concat(state.MeasurementRequirementData);
      return { ...state };
    },

    // 产品图号 Component
    saveComponentList(state, { result: ComponentData }) {
      return {
        ...state,
        ComponentData,
      };
    },
    createComponentList(state, { result: ComponentData }) {
      state.ComponentData = [ComponentData].concat(state.ComponentData);
      return { ...state };
    },
    updateComponentList(state, { result }) {
      state.ComponentData = state.ComponentData.map(item => {
        return item.id === result.id ? result : item;
      });
      return { ...state };
    },

    // 产品所有分类 ProductTypesData
    saveProductTypesList(state, { data: ProductTypesData, typesCount }) {
      return {
        ...state,
        ProductTypesData,
        typesCount,
      };
    },
    // 产品一级分类
    saveCategoryList(state, { result: categoryData }) {
      return {
        ...state,
        categoryData,
      };
    },
    // 产品二级分类
    saveSCategoryList(state, { result: sCategoryData }) {
      return {
        ...state,
        sCategoryData,
      };
    },
    // 删除产品分类
    deleteProductTypeList(state, { result: ProductTypeDeleteMes, payload }) {
      // 检验报错
      return {
        ...state,
        ProductTypeDeleteMes,
      };
    },

    // 车间 WorkShop
    saveWorkShopList(state, { result: WorkShopData }) {
      return {
        ...state,
        WorkShopData,
      };
    },
    createWorkShopList(state, { result: WorkShopData }) {
      state.WorkShopData = [WorkShopData].concat(state.WorkShopData);
      return { ...state };
    },
    updateWorkShopList(state, { result }) {
      state.WorkShopData = state.WorkShopData.map(item => {
        return item.id === result.id ? result : item;
      });
      return { ...state };
    },
    deleteWorkShopList(state, { result: WorkShopDeleteMes, payload }) {
      if (WorkShopDeleteMes && WorkShopDeleteMes.error) {
        // 检验报错
        const newWorkShopData = state.WorkShopData;
        return {
          ...state,
          WorkShopData: newWorkShopData,
          WorkShopDeleteMes,
        };
      }
      if (WorkShopDeleteMes && WorkShopDeleteMes.message === 'ok') {
        const newWorkShopData = state.WorkShopData.filter(item => item.id !== payload);
        return {
          ...state,
          WorkShopData: newWorkShopData,
          WorkShopDeleteMes,
        };
      }
    },

    // 下载文件材料 File
    downloadFileList(state, { result: downloadFile }) {
      return {
        ...state,
        downloadFile,
      };
    },
    // 删除文件材料 File
    deleteFileList(state, { result: payload }) {
      const newProgram =
        state.ComponentData &&
        state.ComponentData[payload.ind] &&
        state.ComponentData[payload.ind].program &&
        state.ComponentData[payload.ind].program.filter(item => item.uid !== payload.uid);
      const data = Object.assign({}, state.ComponentData[payload.ind], { program: newProgram });
      state.ComponentData[payload.ind] = data;
      return {
        ...state,
        ComponentData: state.ComponentData,
      };
    },
    // 删除零件工序 process
    deleteProcessListt(state, { result: payload }) {
      const componetData = state.ComponentData.filter(item => item.id === payload.cid);
      const newProcess = componetData.file.filter(item => item.id !== payload.pid);
      const newComponetData = Object.assign({}, componetData, { file: newProcess });
      state.ComponentData[payload.ind] = newComponetData;
      return {
        ...state,
        ComponentData: state.ComponentData,
      };
    },

    // 网关列表 NetType
    saveNetStatusList(state, { result: NetStatusData }) {
      return {
        ...state,
        NetStatusData,
      };
    },
    saveNetTypeList(state, { result: NetListData }) {
      return {
        ...state,
        NetListData,
      };
    },
    saveNetTypeInfo(state, { result: NetInfoData }) {
      return {
        ...state,
        NetInfoData,
      };
    },
    saveNetDeviceList(state, { data: NetDeviceData, deviceCount }) {
      return {
        ...state,
        NetDeviceData,
        deviceCount
      };
    },
    saveNetDeviceAllList(state, { result: NetDeviceAllData }) {
      return {
        ...state,
        NetDeviceAllData
      };
    },

    // 制造商名称重复验证
    VendorNameTestRes(state, { result: VendorNameTestDate }) {
      return {
        ...state,
        VendorNameTestDate,
      };
    },

    // 大屏名称重复验证
    ScreenNameTestRes(state, { result: ScreenNameTestDate }) {
      return {
        ...state,
        ScreenNameTestDate,
      };
    },

    // 客户名称重复验证
    ClientNameTestRes(state, { result: ClientNameTestDate }) {
      return {
        ...state,
        ClientNameTestDate,
      };
    },

    // 应用账户名称重复验证
    AppNameTestRes(state, { result: AppNameTestDate }) {
      return {
        ...state,
        AppNameTestDate,
      };
    },

    // 人员员工姓名工号重复验证
    UserCheckRes(state, { result: UserCheckResData }) {
      return {
        ...state,
        UserCheckResData,
      };
    },

    // 车间名称重复验证
    WSNameTestRes(state, { result: WSNameTestDate }) {
      return {
        ...state,
        WSNameTestDate,
      };
    },
    // 产线名称重复验证
    PLNameTestRes(state, { result: PLNameTestDate }) {
      return {
        ...state,
        PLNameTestDate,
      };
    },
    // 设备编号重复验证
    DevNumTestRes(state, { result: DevNumTestDate }) {
      return {
        ...state,
        DevNumTestDate,
      };
    },
    // 设备分类名称重复验证
    DevTypeNameTestRes(state, { result: DevTypeNameTestDate }) {
      return {
        ...state,
        DevTypeNameTestDate,
      };
    },
    // 产品分类名称重复验证
    ProTypeNameTestRes(state, { result: ProTypeNameTestDate }) {
      return {
        ...state,
        ProTypeNameTestDate,
      };
    },
    // 设备IP重复验证
    DevIpTestRes(state, { result: DevIpTestDate }) {
      return {
        ...state,
        DevIpTestDate,
      };
    },
    // 产品图号重复验证
    ProNumTestRes(state, { result: ProNumTestDate }) {
      return {
        ...state,
        ProNumTestDate,
      };
    },
    // 产品名称重复验证
    ProNameTestRes(state, { result: ProNameTestDate }) {
      return {
        ...state,
        ProNameTestDate,
      };
    },

    // 人员、订单、产品、客户数据查重导入导出
    checkData(state, { result: checkModalData }) {
      return {
        ...state,
        checkModalData,
      };
    },
    importData(state, { result: importModalData }) {
      return {
        ...state,
        importModalData,
      };
    },
    exportData(state, { result: exportModalData }) {
      return {
        ...state,
        exportModalData,
      };
    },
    // 设备组网文件导出
    exportCNCData(state, { result: CNCModalData }) {
      return {
        ...state,
        CNCModalData,
      };
    },

    // 数据大屏增删改查
    saveScreenList(state, { result: ScreenListData }) {
      return {
        ...state,
        ScreenListData,
      };
    },
    createScreenList(state, { result: ScreenListData }) {
      state.ScreenListData = [ScreenListData].concat(state.ScreenListData);
      return {
        ...state,
      };
    },
    updateScreenList(state, { result }) {
      state.ScreenListData = state.ScreenListData.map(item => {
        return item.id === result.id ? result : item;
      });
      return { ...state };
    },
    deleteScreenList(state, { result: payload }) {
      const newScreenListData = state.ScreenListData.filter(item => item.id !== payload);
      return {
        ...state,
        ScreenListData: newScreenListData,
      };
    },
    saveScreenProcessData(state, { result: ScreenProcessData }) {
      return {
        ...state,
        ScreenProcessData,
      };
    },

    // 所有企业数据
    saveEnterpriseList(state, { data: enterpriseListData, enterCount }) {
      return {
        ...state,
        enterpriseListData,
        enterCount,
      };
    },
    createEnterpriseList(state, { result: enterpriseListData }) {
      state.enterpriseListData = [enterpriseListData].concat(state.enterpriseListData);
      return {
        ...state,
      };
    },
    updateEnterpriseList(state, { result }) {
      const enterUpdateMes = result.error;
      state.enterpriseListData = state.enterpriseListData.map(item => {
        return item.id === result.id ? result : item;
      });
      return {
        ...state,
        enterUpdateMes
      };
    },
    deleteEnterpriseList(state, { result: payload }) {
      const newEnterpriseListData = state.enterpriseListData.filter(item => item.id !== payload);
      return {
        ...state,
        enterpriseListData: newEnterpriseListData,
      };
    },
    enterpriseCheckRes(state, { result: enterpriseCheckData }) {
      return {
        ...state,
        enterpriseCheckData,
      };
    },

    // 所有账户数据
    saveAccountList(state, { data: accountListData, accountCount }) {
      return {
        ...state,
        accountListData,
        accountCount,
      };
    },
    createAccountList(state, { result: accountListData }) {
      state.accountListData = [accountListData].concat(state.accountListData);
      return {
        ...state,
      };
    },
    updateAccountList(state, { result }) {
      state.accountListData = state.accountListData.map(item => {
        return item.id === result.id ? result : item;
      });
      return { ...state };
    },
    deleteAccountList(state, { result: payload }) {
      const newAccountListData = state.accountListData.filter(item => item.id !== payload);
      return {
        ...state,
        accountListData: newAccountListData,
      };
    },
    accountCheckRes(state, { result: accountCheckData }) {
      return {
        ...state,
        accountCheckData,
      };
    },


    // 制造商接口
    // 获取制造商列表
    saveManufacturerList(state, { result: manufacturerData }) {
      return {
        ...state,
        manufacturerData,
      };
    },
    // 制造商所拥企业数据
    saveEnterpriseBelongList(state, { result: EnterpriseBelongListData }) {
      return {
        ...state,
        EnterpriseBelongListData,
      };
    },
    // 制造商所选企业设备统计
    saveCompanyDeviceCount(state, { result: CompanyDeviceCountData }) {
      return {
        ...state,
        CompanyDeviceCountData,
      };
    },
    // 制造商所选企业设备时间利用率
    saveCompanyDeviceRatio(state, { result: CompanyDeviceRatioData }) {
      return {
        ...state,
        CompanyDeviceRatioData,
      };
    },
    // 制造商所选企业设备趋势
    saveCompanyDeviceStat(state, { result: CompanyDeviceStatData }) {
      return {
        ...state,
        CompanyDeviceStatData,
      };
    },
    // 制造商所选企业长期离线设备
    saveCompanyDeviceOffline(state, { result: CompanyDeviceOfflineData }) {
      return {
        ...state,
        CompanyDeviceOfflineData,
      };
    },
    // 重点维护严重报警设备列表（实时）
    saveRealTimeSeriousAlarms(state, { result: RealTimeSeriousAlarmsData }) {
      return {
        ...state,
        RealTimeSeriousAlarmsData,
      };
    },

    // 制造商所选企业设备故障报警
    saveCompanyDeviceDefault(state, { result: CompanyDeviceDefaultData }) {
      return {
        ...state,
        CompanyDeviceDefaultData,
      };
    },
    // 制造商所选企业设备报警类型、次数统计
    saveCompanyAlarmData(state, { result: CompanyAlarmData }) {
      return {
        ...state,
        CompanyAlarmData,
      };
    },
    // 制造商所选企业设备故障报警四个列表
    saveCompanyDefaultTabOne(state, { result: CompanyDefaultTabOneData }) {
      return {
        ...state,
        CompanyDefaultTabOneData,
      };
    },
    saveCompanyDefaultTabTwo(state, { result: CompanyDefaultTabTwoData }) {
      return {
        ...state,
        CompanyDefaultTabTwoData,
      };
    },
    saveCompanyDefaultTabThr(state, { result: CompanyDefaultTabThrData }) {
      return {
        ...state,
        CompanyDefaultTabThrData,
      };
    },
    saveCompanyDefaultTabFour(state, { result: CompanyDefaultTabFourData }) {
      return {
        ...state,
        CompanyDefaultTabFourData,
      };
    },

    // 制造商大屏
    // 地图json数据
    saveGeoJsonData(state, { result: GeoJsonData }) {
      return {
        ...state,
        GeoJsonData,
      };
    },
    // 制造商设备区域数量分布
    saveDeviceDisCount(state, { result: DeviceDisCountData }) {
      return {
        ...state,
        DeviceDisCountData,
      };
    },
    // 制造商设备类型数量占比
    saveDeviceNumberRatio(state, { result: DeviceNumberRatioData }) {
      return {
        ...state,
        DeviceNumberRatioData,
      };
    },

    // 异步获取远程请求列表
    saveRemoteConnectList(state, { data: RemoteConnectListData, listCount }) {
      return {
        ...state,
        RemoteConnectListData,
        listCount
      };
    },
    // 异步获取远程请求记录
    saveRemoteConnectLog(state, { data: RemoteConnectLogData, recordCount }) {
      return {
        ...state,
        RemoteConnectLogData,
        recordCount
      };
    },
    // 异步提交远程请求操作
    saveRemoteConnectData(state, { result: RemoteConnectData }) {
      return {
        ...state,
        RemoteConnectData,
      };
    },
  },
};
